<!--
 - @author: zhaoxm
 - @date: 2023-03-28
 - @description：safety-tips-dialog 风险提示
 -->
<template>
  <el-dialog
      :visible.sync="visible"
      width="520px"
      class="safety-tips-dialog"
      :show-close="true"
      :before-close="close">
    <!--风险提示-->
    <div class="safety-tips-dialog-page">
      <div class="dialog-title">
        <p>{{title}}</p>
      </div>
      <div class="notice-box">
        <p>为保证您的账户安全，建议您绑定手机号码:</p>
        <p>(已注册的，请输入注册时填写的手机号码;未注册过的手机号将自动创建账号)</p>
      </div>
      <div class="warning-container">
        <el-form :model="safetyTipsForm" :rules="rules" ref="safetyTipsForm" label-width="0px">
          <el-form-item prop="mobile">
            <el-input v-model="safetyTipsForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input v-model="safetyTipsForm.passWord" placeholder="请输入密码" type="password"></el-input>
          </el-form-item>
          <el-form-item prop="confirmPwd">
            <el-input v-model="safetyTipsForm.confirmPwd" placeholder="请确认密码" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="operating text-center">
        <el-button type="primary" @click="handleAction">
          确定
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import loginService from "../../../api/loginService";
import storage from "@/utils/storage";

export default {
  props: {
    paramsInfo: {}
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      }else{
        var patten = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (patten.test(value)) {
          callback();
        } else {
          callback(new Error('请输入有效手机号码'))
        }
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
        if (pPattern.test(value)) {
          callback();
        } else {
          callback(new Error('最少6位(包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符)'))
        }

      }
    };

    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.safetyTipsForm.passWord) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      visible:true,
      title:'安全提示',
      safetyTipsForm: {
        passWord: '',
        confirmPwd: '',
        mobile: '',
      },
      rules: {
        mobile: [
          {validator: validatePhone, required: true, trigger: 'blur'}
        ],
        passWord: [
          {validator: validatePass, required: true, trigger: 'blur'}
        ],
        confirmPwd: [
          {validator: validatePass2, required: true, trigger: 'blur'}
        ],
      }


    }
  },
  created() {

  },
  methods: {
    close() {
      //   点击遮罩层通知父组件关闭
      this.$emit("close");
    },
    handleAction(){
      console.log(this.paramsInfo)
      this.$refs.safetyTipsForm.validate((valid) => {
        if(valid){
          this.uuid = sessionStorage.getItem('registerKey');
          //获取验证码接口
          const loading = this.$loading({
            lock: true,
            text: '绑定中...',
            background: 'rgba(255, 255, 255, 0.7)'
          });
          const user = storage.get('user');
          let param = {
            ...this.paramsInfo,
            username: this.safetyTipsForm.mobile,   //手机号码
            passWord: this.safetyTipsForm.passWord,      //密码
          };
          loginService.userInfoBind(param).then(res => {
            loading.close();
            console.log(res)
            if(res.code === '0'){
              this.$message.success('绑定成功');
              this.close();
              storage.set('userBindInfo',res.data);
            }else{
              this.$message.warning(res.msg);
            }
          }).catch((e) => {
            console.log(e);
            loading.close()
          })
        }

        })

    },

  }
}
</script>
<style lang="scss">
.safety-tips-dialog{
  .safety-tips-dialog-page{
    text-align: center;
    .dialog-title{
      p{
        font-size: 24px;
      }
    }
    .notice-box{
      margin: 20px 0px 20px 0px;
      p{
        font-size: 14px;
        text-align: left;
        padding:6px 0px;
      }
    }
    .text-center{
       .el-button{
         width:350px;
       }
    }
  }

}
</style>
