<template>
    <div class="login">
        <img class="login-bg" src="../../assets/images/login_bg.png"/>
        <p class="title">
            自然资源统一确权登记
        </p>
        <el-card class="box-card login-card">
            <div slot="header" class="clearfix">
                <span>用户登录</span>
            </div>
            <div class="Login-type-name">
              <span v-if="currentLoginType === 'weixin'">微信快速登录</span>
              <span v-else>{{ currentLoginName }}</span>
            </div>
            <template>
                <div class="longin-form-box weixin-login" v-if="currentLoginType == 'weixin' && !isScanSuccess">
                  <div style="text-align: center">
                    <img :src="codeImg" style="width: 160px;height: 160px" v-show="!loading">
                  </div>
                  <div class="wechat-text">
                    <p>快速安全登录</p>
                    <p>请使用微信扫二维码登录</p>
                  </div>
                </div>
                <div class="longin-form-box weixin-login scan-success" v-if="currentLoginType == 'weixin' && isScanSuccess">
                  <div style="text-align: center">
                    <i class="el-icon-success"></i>
                  </div>
                  <div class="wechat-text">
                    <p class="p-success">扫描成功</p>
                    <p>在微信中轻触允许即可登录</p>
                  </div>
                </div>
                <div class="longin-form-box account-login" v-if="currentLoginType == 'account'">
                  <el-form :model="loginForm" :rules="rules" ref="loginForm">
                    <el-form-item prop="username" key="username">
                      <i class="el-icon-user"></i>
                      <el-input v-model="loginForm.username"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" key="password">
                      <i class="el-icon-lock"></i>
                      <el-input type="password" v-model="loginForm.password"
                                @keyup.enter.native="login()"></el-input>
                    </el-form-item>
                    <el-form-item class="rem-item">
                      <el-checkbox v-model="loginForm.checked">记住密码</el-checkbox>
                      <a @click="toRestPassword">忘记密码？</a>
                    </el-form-item>
                    <el-form-item class="login-btn">
                      <el-button @click="login()">登录</el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="longin-form-box telephone-login" v-if="currentLoginType == 'telephone'">
                  <el-form :model="SMSLoginForm" :rules="SMSRules" ref="SMSLoginForm">
                    <el-form-item prop="mobile" key="mobile">
                      <i class="el-icon-user"></i>
                      <el-input v-model="SMSLoginForm.mobile"></el-input>
                    </el-form-item>
                    <el-form-item prop="varCode" key="varCode">
                      <i class="el-icon-lock"></i>
                      <el-input v-model="SMSLoginForm.varCode" @keyup.enter.native="login()">
                        <el-button :class="{'sent': isSend}" :disabled="isSend" slot="append" @click="getCode()">
                          <span v-if="!isSend">获取验证码</span>
                          <span v-else>{{SendNum}}S后重发</span>
                        </el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item class="login-btn">
                      <el-button @click="SMSLogin()">登录</el-button>
                    </el-form-item>
                  </el-form>
                </div>
            </template>
            <div class="login-type-tab">
              <div :class="[loginTypeList.length > 2 ? 'type-box' : 'type-box-long']" v-for="(item,index) in loginTypeList" :key="index" v-show="currentLoginType!=item.type">
                <p @click="changeLoginType(item)">{{item.name}}</p>
              </div>
            </div>
            <p class="is-account">
                还没有账号？
                <router-link to="/signup">去注册</router-link>
            </p>
            <p class="is-account">
                不登录
                <router-link to="/index">回到首页</router-link>
            </p>
        </el-card>
    </div>
</template>
<script>
    import storage from '@/utils/storage'
    import {getWxQRCode, userInfo} from "../../api/systemService"
    import SockJS from 'sockjs-client'
    import Stomp from 'webstomp-client'
    import urlConfig from "../../utils/urlConfig";
    import loginService from "../../api/loginService";
    import aes_utils from "../../utils/aes_utils";
    import Cookies from 'js-cookie'
    import safetyTipsDialog from './components/safety-tips-dialog'
    import {GenerateUuid} from '../../utils/uuid-utils';
    import Vue from "vue";

    export default {
        components: {},
        data() {
            var validatePhone = (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入手机号'));
              }else{
                var patten = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
                if (patten.test(value)) {
                  callback();
                } else {
                  callback(new Error('请输入有效手机号码'))
                }
              }
              callback();
            };
            return {
                currentLoginType: 'account',
                currentLoginName:'密码登录',
                isScanSuccess:false,
                loginTypeList:[
                  // {
                  //   name:'扫码登录',
                  //   type:'weixin'
                  // },
                  {
                    name:'密码登录',
                    type:'account'
                  },
                  {
                    name:'短信验证码登录',
                    type:'telephone'
                  }
                ],
                loginForm: {
                    username: '',
                    password: '',
                },
                SMSLoginForm:{
                  mobile:'',
                  varCode:'',
                },
                SendNum:60,
                isSend:false,
                sceneStr: '',
                codeImg: '',
                loading: true,
                rules: {
                    username: [
                        {required: true, message: '请输入用户名称', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                },
                SMSRules:{
                  mobile: [
                    {validator: validatePhone,required: true, trigger: 'blur'}
                  ]
                },
                uuid : '',
            }
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            /**
             * 加载验证码
             */
            getCode() {
              if (this.isPhoneNum(this.SMSLoginForm.mobile)) {
                this.uuid = GenerateUuid();
                sessionStorage.setItem("registerKey", this.uuid)
                //获取验证码接口
                loginService.getIdentifyingCode(this.uuid,this.SMSLoginForm.mobile).then(res => {
                  console.log(res)
                  if(res.data.includes('成功')){
                    this.$message.success('发送成功');
                    this.setTime()
                  }else {
                    this.$message.error(res.data);
                  }
                }).catch(e => {
                  this.$message.error(e);
                })
              }
            },
             setTime() {
              if (this.SendNum === 0) {
                this.isSend = false
                return;
              } else {
                this.isSend = true
                this.SendNum--;
              }
              let _this = this;
              setTimeout(function () {
                _this.setTime()
              }, 1000)//每1000毫秒执行一次
            },
            //校验手机号是否合法
            isPhoneNum(phoneNum) {
              if(phoneNum === ''){
                this.$message.warning("请输入手机号！");
                return false
              }
              var myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
              if (!myreg.test(phoneNum)) {
                this.$message.warning('请输入有效的手机号码！');
                return false;
              }
              return true
            },
            changeLoginType(item){
              this.isScanSuccess = false;
              this.currentLoginName = item.name;
              this.currentLoginType = item.type;
              this.loginForm = {
                username: '',
                password: '',
              };
             this.SMSLoginForm = {
               mobile:'',
               varCode:'',
             };
           },
            login() {
                this.$refs.loginForm.validate((valid) => {
                    console.log(valid)
                    if (valid) {
                        var password_aes = aes_utils.encrypt(this.loginForm.password);
                        if (this.loginForm.checked) {
                            Cookies.set("username", this.loginForm.username, {expires: 7});
                            Cookies.set("password", password_aes, {expires: 7});
                        } else {
                            Cookies.remove("username");
                            Cookies.remove("password");
                        }
                        let result = {
                            username: this.loginForm.username,
                            password: this.loginForm.password
                        };
                        var username_password_aes = aes_utils.encrypt(JSON.stringify(result));
                        let param = {
                            encryptuserinfo: username_password_aes,
                            useValidateCode: "false"
                        };

                        //用户登录接口
                        loginService.login(param).then(user => {
                            // todo login方法返回结果中包含的头像url和根据userId查询用户获得的头像url不一样 以下方法暂时解决头像url不一致问题
                            let temp = user.user_id
                            userInfo(temp).then(res => {
                                if (res.code != '401') {
                                    storage.remove('user')
                                    if (res.data.headportrait != null) {
                                        user.headportrait = res.data.headportrait;
                                    } else {
                                        user.headportrait = '/image/dheader.jpg';
                                    }
                                    storage.set('user', user)
                                }
                            })
                            storage.set('user',user);
                            this.$store.commit("addUser", {user});
                            // 默认跳转到首页
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/index'
                                })
                            }, 1000)
                        },error => {
                            // 成功时
                            this.$message({
                                message: '用户名或密码错误',
                                type: 'error'
                            })
                        })

                    } else {
                        // 失败时
                        return false
                    }
                })
            },
           SMSLogin(){
             this.$refs.SMSLoginForm.validate((valid) => {
               if(valid){
                 if(this.SMSLoginForm.varCode===''){
                   this.$message.warning("请输入验证码！");
                   return
                 }
                 this.uuid = sessionStorage.getItem('registerKey');
                 //获取验证码接口
                 console.log(typeof (this.SMSLoginForm.mobile))
                 loginService.loginByMobile(this.uuid,this.SMSLoginForm.varCode,this.SMSLoginForm.mobile).then(res => {
                     console.log(res)
                     if(res.data && res.data!== null){
                         storage.set('user',res.data);
                         // 默认跳转到首页
                         setTimeout(() => {
                           this.$router.push({
                             path: '/index'
                           })
                         }, 1000)
                     }
                 }).catch((e) => {
                     console.log(e);
                 })
               }
             })
           },
            initWebSocket() {
                // 更换that指针
                const socket = new SockJS(process.env.VUE_APP_ENDPOINT + urlConfig.systemUrl + '/portfolio');
                const options = {debug: false, protocols: Stomp.VERSIONS.supportedProtocols()};
                this.stompClient = Stomp.over(socket,options)
                //建立连接，订阅主题
                this.stompClient.connect({userName: this.sceneStr}, (frame) => {
                    console.log(frame)
                    this.stompClient.subscribe('/user/' + this.sceneStr + '/wxscanCode/message', (val) => {
                      console.log("微信扫码成功:" + val.body);
                      //TODO 这里需要替换扫码成功的样式
                      this.isScanSuccess = true
                    })
                    this.stompClient.subscribe('/user/' + this.sceneStr + '/wxlogin/message', (val) => {
                        console.log("是否绑定信息:" + val);
                        //TODO 这里需根据登录返回结果，判断是否需要录入绑定信息
                        if(val.body.includes('not found user')){
                            this.openSafeTipsDialog(val.body);
                        }else{
                          storage.set('user', val.body);
                          this.$router.push({
                              path: '/index'
                          })
                        }
                    })
                })
            },
            openSafeTipsDialog(data){
              if(data){
                const listJson = JSON.stringify(data);
                const dataList = JSON.parse(listJson)
                console.log(JSON.parse(dataList))
                const node = document.createElement("div");
                document.body.append(node);
                const vm = new Vue({
                  render: (h) =>
                      h(safetyTipsDialog, {
                        props: {
                          paramsInfo:JSON.parse(dataList)?.data ?? {},
                        },
                        on: {
                          close: () => {
                            vm.$destroy();
                            vm.$el?.remove();
                          },
                        },
                      }),
                  parent: this,
                }).$mount(node);
              }

            },
            toRestPassword(){
              this.$router.push({
                name: 'retrievePassword',
              })
           }
        },
        mounted() {
            this.sceneStr = "scend-" + new Date().getTime() + Math.ceil(Math.random() * 888888 + 1000000);
            getWxQRCode({"sceneStr": this.sceneStr,"envVersion": "trial"}).then(res => {
                this.codeImg = "data:image/png;base64," + res.data;
                this.loading = false;
            });
            this.initWebSocket();
            let username = Cookies.get("username");
            let password = Cookies.get("password");
            if (username != null && username != "") {
                this.loginForm.checked = true;
                this.loginForm.username = username;
                this.loginForm.password = aes_utils.decrypt(password);
            }
        },
        created() {
            // 通过content高度，控制页脚的位置
            if (this.$route.name == 'login') {
                document.getElementsByClassName('content')[0].style.minHeight = 'calc(100vh - 140px)';
            }
        }
    }
</script>
<style lang="scss">
    .login {
        min-height:750px;
        .title{
            font-size:32px;
            color:#fff;
            position:absolute;
            top:100px;
            width: 100%;
            text-align: center;
        }
        .login-bg {
            width: 100%;
        }

        .login-card {
            position: absolute;
            width: 460px;
            height: 450px;
            top: 200px;
            left: 50%;
            margin-left: -228px;

            .el-card__header {
                padding: 30px 48px 25px;;
                text-align: center;
                border-bottom: none;

                span {
                    color: #2089FF;
                    font-size: 32px;
                }
            }

            .el-card__body {
                padding: 0px 40px 30px;
                .wechat-text{
                margin-top:18px;
                  p{
                    text-align:center;
                    font-size:16px;
                    line-height:22px;
                  }
                }
                .scan-success{
                   .el-icon-success:before{
                     font-size: 140px;
                     color:#00C78C;
                   }
                  .wechat-text{
                    .p-success{
                      font-size: 22px;
                      padding: 0px 0px 10px 0px;
                    }
                  }
                }
                .el-form {
                .el-form-item {
                  margin-bottom: 20px;
                  .el-form-item__content {
                    font-size: 16px;
                    .el-input-group__append {
                      .el-button {
                        width: 120px;
                        font-size: 16px;
                        color: #fff;
                        text-align: center;
                        padding: 9px 20px 10px;;
                        background-color: #039be5;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                      }

                      .sent {
                        color: #999;
                        cursor: no-drop;
                        background-color: #E5E5E5;
                      }
                    }
                  }


                  [class^=el-icon-] {
                    position: absolute;
                    z-index: 1;
                    top: 12px;
                    left: 12px;
                  }

                  .el-input__inner {
                    padding-left: 36px;
                  }

                  .el-button {
                    width: 100%;
                    padding: 15px 20px;
                    font-size: 18px;
                    color: #FFF;
                    background-color: #0A7DFF;
                    border: none;
                  }

                  .el-button:focus, .el-button:hover {
                    background-color: #66b1ff;
                    border-color: #66b1ff;
                  }
                }

                .rem-item {
                  margin-bottom: 24px;
                  .el-form-item__content {
                    line-height: 20px;
                    display: flex;
                    a{
                      display: inline-block;
                      margin-left: auto;
                      font-size:14px;
                      color:#1AA4E7;
                    }
                  }
                }

                .login-btn {
                  margin-bottom: 2px;
                }
              }
                .Login-type-name{
                  font-size: 20px;
                  text-align: center;
                  padding: 0px 0px 14px 0px;
                }
                .login-type-tab{
                  width:100%;
                  display: flex;
                  margin: 10px 0px 0px 0px;
                  .type-box{
                    width: 50%;
                  }
                  .type-box-long{
                    width: 100%;
                  }
                  P{
                    cursor: pointer;
                    font-size: 14px;
                    text-align: center;
                    width:100%;
                    display: inline-block;
                    color: #1aa4e7;
                    line-height:24px;
                  }
                }
                .el-tabs {
                    .el-tabs__header {
                        margin-bottom: 20px;

                        .el-tabs__nav-wrap::after {
                            height: 0;
                        }

                        .el-tabs__item {
                            width: 152px;
                            font-size: 16px;
                            color: #BFBFBF;
                            text-align: center;
                        }

                        .el-tabs__item.is-active {
                            color: #2089FF;
                        }

                    }
                    .el-tabs__content {

                    }
                }
            }

            .is-account {
                text-align:center;
                font-size:14px;
                color:#999;
                margin-top:16px;
                a{
                    color:#1AA4E7;
                }
            }
        }
    }
</style>
